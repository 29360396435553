/*
 * (c) Verra Technology Corporation
 */

import AudiencesListPanel from '../panels/AudiencesListPanel';
import ChannelsListPanel from '../panels/ChannelsListPanel';
import ContentPanel from '../panels/ContentPanel';
import CreateAudiencePanel from '../panels/CreateAudiencePanel';
import CreateChannelPanel from '../panels/CreateChannelPanel';
import CreateContentPanel from '../panels/CreateContentPanel';
import CreateExperiencePanel from '../panels/CreateExperiencePanel';
import CreateMetricPanel from '../panels/CreateMetricPanel';
import CreateOptimizationPanel from '../panels/CreateOptimizationPanel';
import CreateSitePanel from '../panels/CreateSitePanel';
import DashboardPanel from '../panels/DashboardPanel';
import DocumentationPanel from '../panels/DocumentationPanel';
import EditAudiencePanel from '../panels/EditAudiencePanel';
import EditChannelPanel from '../panels/EditChannelPanel';
import EditContentPanel from '../panels/EditContentPanel';
import EditExperiencePanel from '../panels/EditExperiencePanel';
import EditMetricPanel from '../panels/EditMetricPanel';
import EditOptimizationPanel from '../panels/EditOptimizationPanel';
import EditSitePanel from '../panels/EditSitePanel';
import EventTypesPanel from '../panels/EventTypesPanel';
import ExperiencesPanel from '../panels/ExperiencesPanel';
import ImportContentPanel from '../panels/ImportContentPanel';
import LoadingPanel from '../panels/LoadingPanel';
import LocateElementPanel from '../panels/LocateElementPanel';
import ManageAccountPanel from '../panels/ManageAccountPanel';
import MetricsListPanel from '../panels/MetricsListPanel';
import OptimizationsPanel from '../panels/OptimizationsPanel';
import SitesPanel from '../panels/SitesPanel';
import ViewChannelPanel from '../panels/ViewChannelPanel';
import ViewOptimizationPanel from '../panels/ViewOptimizationPanel';

/**
 * Maps components defined in AdminConfigData.json to Component classes. This ensures the Component class is 
 * built into the code.
 */
const StateComponentMap = {

	LoadingPanel: LoadingPanel,
	DashboardPanel: DashboardPanel,
	SitesPanel: SitesPanel,

	OptimizationsPanel: OptimizationsPanel,
	CreateOptimizationPanel: CreateOptimizationPanel,
	EditOptimizationPanel: EditOptimizationPanel,
	ViewOptimizationPanel: ViewOptimizationPanel,
	LocateElementPanel: LocateElementPanel,

	ExperiencesPanel: ExperiencesPanel,
	CreateExperiencePanel: CreateExperiencePanel,
	EditExperiencePanel: EditExperiencePanel,

	AudiencesListPanel: AudiencesListPanel,
	CreateAudiencePanel: CreateAudiencePanel,
	EditAudiencePanel: EditAudiencePanel,

	MetricsListPanel: MetricsListPanel,
	CreateMetricPanel: CreateMetricPanel,
	EditMetricPanel: EditMetricPanel,
	
	ChannelsListPanel: ChannelsListPanel,
	ViewChannelPanel: ViewChannelPanel,
	EditChannelPanel: EditChannelPanel,
	CreateChannelPanel: CreateChannelPanel, 

	ContentPanel: ContentPanel,
	CreateContentPanel: CreateContentPanel,
	EditContentPanel: EditContentPanel,
	ImportContentPanel: ImportContentPanel,

	ManageAccountPanel: ManageAccountPanel,
	CreateSitePanel: CreateSitePanel,
	EditSitePanel: EditSitePanel,
	EventTypesPanel: EventTypesPanel,
	DocumentationPanel: DocumentationPanel

};

export default StateComponentMap;