/*
 * (c) Verra Technology Corporation
 */

import SphereAdminServices from '../../model/SphereAdminServices';
import SphereAdminSession from '../../model/SphereAdminSession';
import ServiceRequestCommand from '../ServiceRequestCommand';

/**
 * Initiates subscription management through Stripe
 */
class ManageSubscriptionRequest extends ServiceRequestCommand {

	#redirect;

	/**
	 * @return The service endpoint URL part. This is appended to SphereAdminServices.BASE_URL
	 */
    getService() {
		return SphereAdminServices.MANAGE_SUBSCRIPTION;
	}

	/**
	 * @return The parameters to send with the request
	 */
	getParams() {
		return { customerId: SphereAdminSession.selectedAccount.customerId };
	}

   /**
	* Handles completion of the command
	*/
	handleComplete() {
		const response = this.getResponse();
		// console.info( 'ManageSubscriptionRequest::handleComplete', response );
		if( response.success ) this.#redirect = response.result.redirect;
	}

	/**
	 * The redirect URL for managing the subscription through Stripe
	 */
	getRedirect() {
		return this.#redirect;
	}
 
}

export default ManageSubscriptionRequest;
