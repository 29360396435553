/*
 * (c) Verra Technology Corporation
 */

import Command from '../../commands/Command.mjs';
import Alert from '../components/controls/Alert';
import UpSellComponent from '../components/UpSellComponent';
import PermissionsManager from '../managers/PermissionsManager';
import AdminStates from '../model/AdminStates';
import Permissions from '../model/Permissions';
import SphereAdminSession from '../model/SphereAdminSession';
import CreateSiteCommand from './CreateSiteCommand';
import OpenModalCommand from './OpenModalCommand';
import SetStateCommand from './SetStateCommand';

/**
 * Initiates the view to create an Optimization
 */
class CreateOptimizationCommand extends Command {
	
	/**
	 * Handles execution of the command
	 */
	handleExecute() {

		// ensure there is at least one site
		if( SphereAdminSession.sites.length === 0 ) {
			const alert = <Alert content='You must have a site set up before you can create optimizations. Would you like to do this now?' okHandler={ this.#handleCreateSite.bind( this ) }/>;
			const openModal = new OpenModalCommand( 'No Site Configured', alert, '500px', true );
			openModal.execute();
		} else {
			const hasPermission = PermissionsManager.hasPermissionForMore( Permissions.OPTIMIZATIONS_MAX );
			// console.info( hasPermission );
			if( hasPermission.allowed ) {
				const setState = new SetStateCommand( AdminStates.ADMIN_OPTIMIZATIONS_CREATE );
				setState.execute();
			} else {
				const msg = <p style={{ margin: 0 }}>You've reached your maximum number of allowed Optimizations. Your account only allows { hasPermission.permitted }.</p>;
				const upSell = <UpSellComponent message={ msg } callout='optimizations'/>;
				const openModal = new OpenModalCommand(  'Limit Reached', upSell, '600px', true );
				openModal.execute();
			}
		}
		
	}

	/**
	 * Handles the click to create a site if one does not exist
	 */
	#handleCreateSite() {
		const createSite = new CreateSiteCommand();
		createSite.execute();
	}

}

//

export default CreateOptimizationCommand;
