/*
 * (c) Verra Technology Corporation
 */

import Command from '../../commands/Command.mjs';
import UpSellComponent from '../components/UpSellComponent';
import PermissionsManager from '../managers/PermissionsManager';
import Permissions from '../model/Permissions';
import SphereAdminSession from '../model/SphereAdminSession';
import CloseModalCommand from './CloseModalCommand';
import OpenModalCommand from './OpenModalCommand';

/**
 * Sets the state to create experience
 */
class AddExperiencesToOptimiztionCommand extends Command {
	
	#experiences;

	//

	/**
	 * Constructs the AddExperienceToOptimiztionCommand
	 */
	constructor( experiences ) {
		super();
		this.#experiences = experiences;
	}

	/**
	 * Handles execution of the command
	 */
	handleExecute() {
		const optimizationExperiences = SphereAdminSession.optimization.experiences; 
		for( let i = 0; i < this.#experiences.length; i++ ) {
			const experienceToAdd = this.#experiences[ i ];
			const doesNotHaveExperience = ( optimizationExperiences.find( experience => experience === experienceToAdd ) == null );
			if( doesNotHaveExperience ) {
				const hasPermission = PermissionsManager.hasPermissionForMoreItems( Permissions.OPTIMIZATION_EXPERIENCES_MAX, optimizationExperiences );
				if( hasPermission.allowed ) {
					optimizationExperiences.push( experienceToAdd );
				} else {
					window.setTimeout( () => { // I don't even know wtf, something weird with the timing of setting states. Remove this and watch the modals fail spectacularly
						// close the select experience modal
						const closeModal = new CloseModalCommand();
						closeModal.execute();
		
						// open the upsell modal
						const msg = <p style={{ margin: 0 }}>You've reached your maximum number of allowed Experiences per Optimization. Your account only allows { hasPermission.permitted }.</p>;
						const upSell = <UpSellComponent message={ msg } callout='optimization_experiences'/>;
						const openModal = new OpenModalCommand(  'Limit Reached', upSell, '600px', true );
						openModal.execute();
					}, 5 );
					break;
				}
			}
		}
	}
	
}

//

export default AddExperiencesToOptimiztionCommand;
