/*
 * (c) Verra Technology Corporation
 */

import Command from '../../commands/Command.mjs';
import UpSellComponent from '../components/UpSellComponent';
import PermissionsManager from '../managers/PermissionsManager';
import Permissions from '../model/Permissions';
import SphereAdminSession from '../model/SphereAdminSession';
import CloseModalCommand from './CloseModalCommand';
import OpenModalCommand from './OpenModalCommand';

/**
 * Sets the state to create metric
 */
class AddMetricsToOptimiztionCommand extends Command {
	
	#metrics;

	//

	/**
	 * Constructs the AddMetricsToOptimiztionCommand
	 */
	constructor( metrics ) {
		super();
		this.#metrics = metrics;
	}

	/**
	 * Handles execution of the command
	 */
	handleExecute() {
		const optimizationMetrics = SphereAdminSession.optimization.metrics; 
		const hasMetrics = ( optimizationMetrics.length > 0 );
		for( let i = 0; i < this.#metrics.length; i++ ) {
			const metricToAdd = this.#metrics[ i ];
			const doesNotHaveMetric = ( optimizationMetrics.find( metric => metric === metricToAdd ) == null );
			if( doesNotHaveMetric ) {
				const hasPermission = PermissionsManager.hasPermissionForMoreItems( Permissions.OPTIMIZATION_METRICS_MAX, optimizationMetrics );
				if( hasPermission.allowed ) {
					optimizationMetrics.push( metricToAdd );
					if( !hasMetrics && i === 0 ) metricToAdd.primary = true;
				} else {
					window.setTimeout( () => { // I don't even know wtf, something weird with the timing of setting states. Remove this and watch the modals fail spectacularly
						// close the select metric modal
						const closeModal = new CloseModalCommand();
						closeModal.execute();
		
						// open the upsell modal
						const msg = <p style={{ margin: 0 }}>You've reached your maximum number of allowed Metrics per Optimization. Your account only allows { hasPermission.permitted }.</p>;
						const upSell = <UpSellComponent message={ msg } callout='optimization_metrics'/>;
						const openModal = new OpenModalCommand( 'Limit Reached', upSell, '600px', true );
						openModal.execute();
					}, 5 );
					break;
				}
			}
		}
	}
	
}

//

export default AddMetricsToOptimiztionCommand;
