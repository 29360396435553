/*
 * (c) Verra Technology Corporation
 */

import React, { Component } from 'react';
import ModifiableObject from '../../../model/ModifiableObject.mjs';
import Optimization from '../../../model/Optimization';
import OptimizationModelTypes from '../../../model/OptimizationModelTypes';
import PublishableObject from '../../../model/PublishableObject.mjs';
import CreateExperienceForOptimizationCommand from '../../commands/CreateExperienceForOptimizationCommand';
import EditOptimizationCommand from '../../commands/EditOptimizationCommand';
import OpenModalCommand from '../../commands/OpenModalCommand';
import PublishOptimizationCommand from '../../commands/PublishOptimizationCommand';
import SetOptimizationTypeCommand from '../../commands/SetOptimizationTypeCommand';
import SetStateCommand from '../../commands/SetStateCommand';
import ValidatorCommand from '../../commands/ValidatorCommand';
import AdminStates from '../../model/AdminStates';
import ObjectStatusMap from '../../model/ObjectStatusMap';
import SphereAdminSession from '../../model/SphereAdminSession';
import SaveOptimizationRequest from '../../requests/optimizations/SaveOptimizationRequest';
import Alert from '../controls/Alert';
import DropDownField from '../controls/DropDownField';
import Hint from '../controls/Hint';
import InputField from '../controls/InputField';
import TabComponent from '../controls/TabComponent';
import ExperiencesTab from '../optimizations/ExperiencesTab';
import MetricsTab from '../optimizations/MetricsTab';
import TargetingTab from '../optimizations/TargetingTab';

//

/**
 * Defines the model type labels
 */
const modelTypes = [
	{ label: 'A/B', type: OptimizationModelTypes.AB },
	{ label: 'Personalization', type: OptimizationModelTypes.PERSONALIZATION },
	// Multivariate
	// Multi Armed Bandit
	// { label: 'Predictive', type: OptimizationModelTypes.PREDICTIVE }
];

/**
 * Defines labels for the model types
 */
const modelTypeLabels = {
	'1': 'A/B',
	'5': 'Personalization',
	// '4': 'Predictive'
};

//

/**
 * The OptimizationEditor contains the UI for creating and editing Optimizations
 */
class OptimizationEditor extends Component {
	
	/**
	 * Constructs the panel.
	 */
	 constructor() {
		super();

		this.state = {
			selectedExperiences: []
		};

		this.experiencesGridRef = React.createRef();
	}

	/**
	 * Renders the component
	 * @see react docs
	 */
	render() {
		const optimization = SphereAdminSession.optimization;
		const isEditing = ( SphereAdminSession.currentState === AdminStates.ADMIN_OPTIMIZATIONS_EDIT );
		const title = ( isEditing ) ? 'Edit Optimization' : 'Create Optimization';
		const saveDisabled = optimization.status === ModifiableObject.SAVED || optimization.locked || optimization.status === Optimization.COMPLETE;
		const saveButtonsDisabledClass = ( saveDisabled ) ? ' disabled' : '';

		return <div className='content-panel optimization-editor'>
					<div className='grid'>
						<div className='grid-cell default-50'>
							<h2>{title}</h2>
							<div className='breadcrumb'>
								<a href='/optimizations/' className='breadcrumb'>Optimizations</a> / { optimization.name }
							</div>
						</div>
						{ optimization.status !== PublishableObject.PUBLISHED && optimization.status !== Optimization.COMPLETE && 
							<div className='grid-cell default-50 align-right header-actions'>
								<button 
									className={ 'primary-button control-pad-left' + saveButtonsDisabledClass } 
									disabled={ saveDisabled } style={{ width: '80px' }}
									onClick={ this.#handleSave.bind( this )}>Save</button>
								<button 
									className={'button control-pad-left'} 
									style={{width: '80px'}} 
									onClick={ this.#handleCancel.bind( this )}>Cancel</button>
								<span className='divider control-pad-left'>|</span>
								<button 
									className={ 'attention-button control-pad-left' } 
									style={{ width: '80px' }}
									onClick={ this.#handlePublish.bind( this )}>Publish</button>
							</div>
						}
					</div>
					{ optimization.status === PublishableObject.PUBLISHED &&
						<div className='panel-cell published-header'>Optimization has been published</div>
					}
					{ optimization.status === Optimization.COMPLETE &&
						<div className='panel-cell complete-header'>Optimization is complete</div>
					}
					{ this.#getPrimaryFieldsMarkup() }
					{ optimization.type === OptimizationModelTypes.AB && this.#getABContent() }
					{ optimization.type === OptimizationModelTypes.PERSONALIZATION && this.#getPersonalizationContent() }
				</div>;
	}

	/**
	 * @return The markup for displaying id, name, and model fields
	 */
	#getPrimaryFieldsMarkup(){
		const optimization = SphereAdminSession.optimization;
		
		const nameToolTip = 'User friendly name for the Optimization.';
		const modelToolTip = 'The model defines how the Optimization chooses the experience in which to display. Refer to the help documentation for more information. This can only be set during creation.';
		const siteToolTip = 'The site in which the Optimization will apply. This cannot be changed after the Optimization is published.';
		const status = ( optimization.locked ) ? 'locked' : ObjectStatusMap[ optimization.status ];
		const hasReadOnlyFields = ( SphereAdminSession.currentState === AdminStates.ADMIN_OPTIMIZATIONS_EDIT );
		const selectedModelIndex = modelTypes.findIndex( modelType => modelType.type === optimization.type );
		const selectedSiteIndex = SphereAdminSession.sites.findIndex( site => site.id === optimization.siteId );
		const isPublished = ( optimization.status === PublishableObject.PUBLISHED );
		const isComplete = ( optimization.status === Optimization.COMPLETE );
		const singleSite = ( SphereAdminSession.sites.length === 1 );

		return 	<div className='panel-cell primary-fields'>
					<div style={{ flexBasis: 0, flexGrow: 3, position: 'relative' }}>
						<div className={'status-indicatator ' + status}></div>
						<label>Name <Hint width='250px' content={ nameToolTip }/></label>
						<InputField 
							value={ optimization.name } 
							maxLength='256' 
							readOnly={ isPublished || isComplete } 
							onChange={( value ) => { this.#handleFieldChanged( 'name', value ); }}/>
					</div>
					{ !singleSite && 
						<div style={{ flexBasis: 0, flexGrow: 1 }}>
							<label>Site <Hint width='250px' position='left' content={ siteToolTip }/></label>
							<DropDownField 
								labelField='name' 
								items={ SphereAdminSession.sites }
								selectedIndex={ selectedSiteIndex } 
								disabled={ isPublished || isComplete } 
								changeHandler={( value ) => { this.#handleSiteChanged( value ); }}/>
						</div>
					}
					<div style={{ flexBasis: 0, flexGrow: 1 }}>
						<label>Type <Hint width='250px' position='left' content={ modelToolTip }/></label>
						{ !hasReadOnlyFields && 
							<DropDownField 
								labelField='label' 
								items={ modelTypes }
								selectedIndex={ selectedModelIndex } 
								changeHandler={( value ) => { this.#handleTypeChanged( value ); }}/>
						}
						{ hasReadOnlyFields && 
							<span className='read-only'>{ modelTypeLabels[ optimization.type ] }</span>
						}
					</div>
				</div>;
	}

	/**
	 * @return the content for editing AB test optimizations
	 */
	#getABContent() {
		const tabs = [
			{ 
				label: 'Experiences', 
				content: <ExperiencesTab 
							changeHandler={ this.#handleOptimizationChange.bind( this )} 
							saveOptimizationHandler={ () => this.#save( this.#handleSaveCompleteThenCreateExperience.bind( this )) }/> 
			},
			{ label: 'Audiences', content: <TargetingTab changeHandler={ this.#handleOptimizationChange.bind( this )}/> },
			{ label: 'Metrics', content: <MetricsTab changeHandler={ this.#handleOptimizationChange.bind( this )}/> },
		];
		return <div style={{ marginTop: 13 }}>
			<TabComponent tabs={ tabs }/>
		</div>;
	}

	/**
	 * @return the content for editing personaliztion optimizations
	 */
	#getPersonalizationContent() {
		const tabs = [
			{ label: 'Audiences', content: <TargetingTab 
												changeHandler={ this.#handleOptimizationChange.bind( this )} 
												saveOptimizationHandler={ () => this.#save( this.#handleSaveCompleteThenCreateExperience.bind( this )) }/> },
			{ label: 'Metrics', content: <MetricsTab changeHandler={ this.#handleOptimizationChange.bind( this )}/> },
		];
		return <div style={{ marginTop: 13 }}>
			<TabComponent tabs={ tabs }/>
		</div>;
	}

	//

	/**
	 * Handles changes to the input fields, invalidating the Channel object
	 */
	#handleFieldChanged( field, value ) {
		// console.info( field, value );
		SphereAdminSession.optimization[ field ] = value;
		SphereAdminSession.optimization.status = ModifiableObject.MODIFIED;
		this.setState({}); // force a redraw
	}

	/**
	 * Handles changes to the selection of the Optimization's site
	 */
	#handleSiteChanged( site ) {
		const optimization = SphereAdminSession.optimization;
		optimization.siteId = site.id;
	}

	/**
	 * Handles changes to the selection of the Optimization's type
	 */
	#handleTypeChanged( modelType ) {
		const setType = new SetOptimizationTypeCommand( modelType.type );
		setType.execute();
		this.setState({});
	}

	/**
	 * Handles a change to the Optimization from one of the editing tabs
	 */
	#handleOptimizationChange() {
		SphereAdminSession.optimization.status = ModifiableObject.MODIFIED;
		this.setState({});
	}

	// Save, Cancel, Publish

	/**
	 * Handles a click on the save button, saves the Optimization 
	 */
	#handleSave() {
		this.#save( this.#handleSaveComplete.bind( this ));
	}

	/**
	 * Handles a click on the save button, saves the Optimization 
	 */
	#save( handler ) {
		const fields = { 
			name: ValidatorCommand.isNotNullOrEmpty, 
			siteId: ValidatorCommand.isNotNullOrEmpty
		};

		const validateSite = new ValidatorCommand( SphereAdminSession.optimization, fields );
		const isValid = validateSite.execute();

		if( isValid ) {
			SphereAdminSession.loading = true;
			const saveOptimization = new SaveOptimizationRequest( SphereAdminSession.optimization );
			saveOptimization.execute( handler );
		} else {
			
			const invalidFields = validateSite.getInvalidFields();
			const invalidFieldsElements = [];
	
			var i = 0;
			invalidFields.forEach( field => {
				invalidFieldsElements.push( <li key={ i++ }>{ field }</li> );
			});
	
			const content = <div className='alert'>
				The Optimization cannot be saved. The following fields are invalid or incomplete:
				<ul>{ invalidFieldsElements }</ul>
			</div>;

			const openModal = new OpenModalCommand( 'Invalid Optimization', content, '500px', true );
			openModal.execute();
		}
	}

	/**
	 * Handles completion of the save channel reques
	 */
	#handleSaveComplete( command ){
		SphereAdminSession.loading = false;
		if( command.response.success ) {
			if( SphereAdminSession.currentState === AdminStates.ADMIN_OPTIMIZATIONS_CREATE ){
				const editOptimization = new EditOptimizationCommand( SphereAdminSession.optimization.id );
				editOptimization.execute();
			} else {
				this.setState({});
			}
		}
	}

	/**
	 * Handles a click on the cancel button
	 */
	#handleCancel() {
		const hasChanged = SphereAdminSession.optimization.status === ModifiableObject.MODIFIED || SphereAdminSession.optimization.status === ModifiableObject.CREATED;
		if( hasChanged ){
			const alert = <Alert content='You have unsaved changes, are you sure you want to exit?' okHandler={ this.#handleCancelConfirm.bind( this ) }/>;
			const openModal = new OpenModalCommand( 'Are you sure?', alert, '500px', true );
			openModal.execute();
		} else {
			const setState = new SetStateCommand( AdminStates.ADMIN_OPTIMIZATIONS );
			setState.execute();
		}
	}

	/**
	 * Handles a confirmation to cancel changes
	 */
	#handleCancelConfirm() {
		const setState = new SetStateCommand( AdminStates.ADMIN_OPTIMIZATIONS );
		setState.execute();
	}

	/**
	 * Handles a click to publish the Optimization
	 */
	#handlePublish() {
		if( SphereAdminSession.optimization.status !== ModifiableObject.SAVED && SphereAdminSession.optimization.status !== PublishableObject.PUBLISHED ) {
			this.#save( this.#handleSaveCompleteThenPublish.bind( this ));
		} else {
			this.#handleSaveCompleteThenPublish();
		}
	}

	// 

	/**
	 * Handles a click to publish the Optimization
	 */
	#handleSaveCompleteThenPublish( command ) {
		if( command == null || command?.response.success ) {
			
			const optimization = SphereAdminSession.optimization;
			let isValid = true;
			let error;

			if( optimization.type === OptimizationModelTypes.PERSONALIZATION && optimization.audiences.length === 0 ) {
				isValid = false;
				error = 'You must add at least one audience to the optimization';
			} else if( optimization.experiences.length === 0 ) {
				isValid = false;
				error = 'You must add at least one experience to the optimization';
			} else if( optimization.metrics.length === 0 ) {
				isValid = false;
				error = 'You must add at least one metric to the optimization';
			}

			if( isValid ) {
				SphereAdminSession.loading = true;
				const publish = new PublishOptimizationCommand();
				publish.execute( this.#handlePublishComplete.bind( this ));
			} else {
				SphereAdminSession.loading = false;
				const alert = <Alert content={ error } showCancelBtn={ false }/>;
				const openModal = new OpenModalCommand( 'Invalid Optimization', alert, '500px', true );
				openModal.execute();
			}

		} else {
			SphereAdminSession.loading = false;
		}
	}

	/**
	 * Handles completion of the publish optimization command
	 */
	#handlePublishComplete( command ){
		SphereAdminSession.loading = false;
		if( command.getSuccess() ) {
			if( SphereAdminSession.currentState === AdminStates.ADMIN_OPTIMIZATIONS_CREATE ){
				const editOptimization = new EditOptimizationCommand( SphereAdminSession.optimization.id );
				editOptimization.execute();
			} else {
				this.setState({});
			}
		}
	}

	/**
	 * Handles the completion of the save, initiates the creation of a new Experience
	 */
	#handleSaveCompleteThenCreateExperience( command ) {
		if( command.response.success ) {
			SphereAdminSession.loading = false;
			const createExperience = new CreateExperienceForOptimizationCommand( SphereAdminSession.optimization.id );
			createExperience.execute();
		}
	}

}

//

export default OptimizationEditor;
