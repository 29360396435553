/*
* (c) Verra Technology Corporation
*/

import React, { Component } from 'react';

//

/**
 * Modal used to show deactivated account details
 */
class DeactivatedAccountModal extends Component {

	/**
	'* Constructs the Component
	*/
	constructor( props ) {
		super( props );
		this.state = { };
	}
	
	/**
	* Renders the component
	* @see react docs
	*/
	render() {
		return (
			<div className='dialog'>
				<p>Your account has been deactivated due to cancelation of your subscription. If you'd like to reactivate your account or downgrade to a free plan please reach out to <a href='mailto:support@verra.ai'>support@verra.ai</a>. Please note, deactivated accounts will be removed after 30 days.</p>
			</div>
		);
	}

}

export default DeactivatedAccountModal;