/*
 * (c) Verra Technology Corporation
 */

import React, { Component } from 'react';
import ModifiableObject from '../../../model/ModifiableObject.mjs';
import Optimization from '../../../model/Optimization';
import PublishableObject from '../../../model/PublishableObject.mjs';
import AddExperiencesToOptimiztionCommand from '../../commands/AddExperiencesToOptimiztion';
import CreateExperienceForOptimizationCommand from '../../commands/CreateExperienceForOptimizationCommand';
import OpenModalCommand from '../../commands/OpenModalCommand';
import SphereAdminSession from '../../model/SphereAdminSession';
import Alert from '../controls/Alert';
import DataGrid from '../controls/DataGrid';
import Hint from '../controls/Hint';
import SelectExperienceModal from '../modals/SelectExperienceModal';

//

/**
 * The ExperiencesTab contains the UI for setting up and managing the experiences for Optimizations
 */
class ExperiencesTab extends Component {
	
	/**
	* Constructs the Component.
	*/
	constructor() {
		super();
		this.state = {
			experiencesSelected: false
		};
		this.experiencesGridRef = React.createRef();
	}

	/**
	* Renders the component
	* @see react docs
	*/
	render() {
		const optimization = SphereAdminSession.optimization;
		const contentToolTip = 'Here is where you specify the experiences that will be displayed by the Optimization';
		const noContentMsg = <div className='grid-cell default-100'>No experiences have been assigned to the Optimization. Use the buttons to the right to create and add experiences.</div>;
		const buttonsDisabled = ( optimization.locked ) ? 'disabled' : '';
		const removeDisabled = ( !optimization.locked && this.state.experiencesSelected ) ? '' : 'disabled';
		const isPublished = ( optimization.status === PublishableObject.PUBLISHED );
		const isComplete = ( optimization.status === Optimization.COMPLETE );

		const headers = [ 
			{ label: 'Variation', field: 'name', width: 90 },
		];

		return 	<div>
					<div className='content-panel'>
						<div className='grid panel-cell'>
							<div className='grid-cell default-60'>
								<h3>Experience Variations <Hint width='250px' content={ contentToolTip }/></h3>
							</div>
							{ ( !isPublished && !isComplete ) &&
								<div className='grid-cell default-40 align-right'>
									<button className={ 'button ' + buttonsDisabled} onClick={ this.#handleCreateExperience.bind( this ) }>Create</button>
									<button className={ 'button control-pad-left ' + buttonsDisabled } onClick={ this.#handleAddExperience.bind( this )}>Add</button>
									<button className={ 'button control-pad-left ' + removeDisabled } onClick={ this.#handleRemoveExperience.bind( this )}>Remove</button>
								</div>
							}
						</div>
					</div>
					<DataGrid 
						ref={ this.experiencesGridRef } 
						headers={ headers } 
						data={ optimization.experiences } 
						idField='id' 
						checkColumn={ true } 
						checkOnRowClick={ true }
						showHeaderIfEmpty={ false } 
						statusIndicator={ false } 
						disabled={ optimization.locked || isPublished || isComplete } 
						maxBodyHeight='300px'
						noContent={ noContentMsg }
						checkBoxChangeHandler={ this.#handleGridCheckBoxSelect.bind( this )}
					/>
				</div>;
	}
	
	//
	
	// Experiences Handlers

	/**
	 * Handles the click to add content
	 */
	#handleCreateExperience() {
		const hasChanged = SphereAdminSession.optimization.status === ModifiableObject.MODIFIED || SphereAdminSession.optimization.status === ModifiableObject.CREATED;
		if( hasChanged ){
			const alert = <Alert content='You have unsaved changes. Click OK to save and continue.' okHandler={ this.#handleCreateConfirm.bind( this ) }/>;
			const openModal = new OpenModalCommand( 'Are you sure?', alert, '500px', true );
			openModal.execute();
		} else {
			const createExperience = new CreateExperienceForOptimizationCommand();
			createExperience.execute();
		}		
	}

	/**
	 * Handles the click to add content
	 */
	#handleCreateConfirm() {
		this.props.saveOptimizationHandler();
	}

	/**
	 * Handles the click to add content
	 */
	#handleAddExperience() {
		const selectExperienceModal = <SelectExperienceModal 
											selectHandler={ this.#handleExperienceSelectedForAdd.bind( this )} 
											createHandler={ this.#handleCreateExperience.bind( this )}/>;
		const openModal = new OpenModalCommand( 'Select Experience', selectExperienceModal, '700px', false );
		openModal.execute();
	}

	/**
	 * Handles selection of Audiences in the SelectAudienceModal
	 */
	#handleExperienceSelectedForAdd( selectedExperiences ) {
		
		// const experiences = SphereAdminSession.optimization.experiences;
		// selectedExperiences.forEach(( selectedExperience ) => {
		// 	if( experiences.find( experience => experience === selectedExperience ) == null ) {
		// 		experiences.push( selectedExperience );
		// 	}
		// })

		const addExperiences = new AddExperiencesToOptimiztionCommand( selectedExperiences );
		addExperiences.execute();

		this.experiencesGridRef.current.unCheckAll();
		this.props.changeHandler();
		this.setState({});
	}

	/**
	 * Handles clicks on a checkbox in the Content grid
	 */
	#handleGridCheckBoxSelect( grid ) {
		const selected = grid.getChecked();
		this.setState({ experiencesSelected: ( selected.length > 0 )});
	}

	/**
	 * Handles a click on the remove content button
	 */
	#handleRemoveExperience() {
		const selectedExperiences = this.experiencesGridRef.current.getChecked();
		const optimization = SphereAdminSession.optimization;
		optimization.experiences = optimization.experiences.filter(( experience ) => {
			const matchedExperience = selectedExperiences.find( selectedExperience => experience === selectedExperience );
			return matchedExperience == null;
		});
		this.experiencesGridRef.current.unCheckAll();
		this.props.changeHandler();
		this.setState({ experiencesSelected: false });
	}

}

//

export default ExperiencesTab;
