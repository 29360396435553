/*
 * (c) Verra Technology Corporation
 */

import SphereAdminServices from '../../model/SphereAdminServices';
import SphereAdminSession from '../../model/SphereAdminSession';
import ServiceRequestCommand from '../ServiceRequestCommand';

/**
 * Initiates subscription upgrade through Stripe
 */
class UpgradeSubscriptionRequest extends ServiceRequestCommand {

	#productId;
	#redirect;

	/**
	 * Constructs the request
	 */
	constructor( productId ) {
		super();
		this.#productId = productId;
	}

	/**
	 * @return The service endpoint URL part. This is appended to SphereAdminServices.BASE_URL
	 */
    getService() {
		return SphereAdminServices.UPGRADE_SUBSCRIPTION;
	}

	/**
	 * @return The parameters to send with the request
	 */
	getParams() {
		return { 
			email: SphereAdminSession.user.email,
			customerId: SphereAdminSession.selectedAccount.customerId, 
			subscriptionId: SphereAdminSession.selectedAccount.subscriptionId, 
			productId: this.#productId,
			returnUrl: window.location.href,
		};
	}

   /**
	* Handles completion of the command
	*/
	handleComplete() {
		const response = this.getResponse();
		// console.info( 'UpgradeSubscriptionRequest::handleComplete', response );
		if( response.success ) this.#redirect = response.result.redirect;
	}

	/**
	 * The redirect URL for managing the upgrade through Stripe
	 */
	getRedirect() {
		return this.#redirect;
	}
 
}

export default UpgradeSubscriptionRequest;
