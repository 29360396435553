/*
 * (c) Verra Technology Corporation
 */

/**
 * Constants for sphere services urls
 */
var SphereAdminServices = {
	
	BASE_URL: process.env.REACT_APP_ADMIN_SERVICES_URL || 'https://localhost:3001',
	// BASE_URL: 'https://admin-services.verra.ai',

	VERIFY_AUTH: '/auth/verify/',
	AUTHENTICATE: '/auth/user/',
	FORGOT_PASSWORD: '/auth/forgot-password/',
	RESET_PASSWORD: '/auth/reset-password/',

	REGISTER: '/account/user/register/',
	RETRIEVE_USER: '/account/user/get/',
	RETRIEVE_OBJECT_COUNTS: '/account/objects/',
	MANAGE_SUBSCRIPTION: '/account/manage/',
	UPGRADE_SUBSCRIPTION: '/account/upgrade/',

	RETRIEVE_ANALYTICS: '/analytics/events',
	
	SEARCH_OPTIMIZATIONS: '/optimizations/search/',
	RETRIEVE_OPTIMIZATIONS: '/optimizations/list/',
	RETRIEVE_OPTIMIZATION: '/optimizations/get/',
	SAVE_OPTIMIZATION: '/optimizations/save/',
	PUBLISH_OPTIMIZATION: '/optimizations/publish/',
	ADD_EXPERIENCE_TO_OPTIMIZATION: '/optimizations/add-experience/',
	DELETE_OPTIMIZATIONS: '/optimizations/delete/',
	COMPLETE_OPTIMIZATION: '/optimizations/complete/',

	RETRIEVE_OPTIMIZATION_IMPRESSION_ANALYTICS: '/analytics/optimization/impressions',
	RETRIEVE_OPTIMIZATION_ENGAGEMENT_ANALYTICS: '/analytics/optimization/engagement',
	RETRIEVE_OPTIMIZATION_CONVERSION_ANALYTICS: '/analytics/optimization/conversion',

	RETRIEVE_AUDIENCES_LIST: '/audiences/list/',
	SAVE_AUDIENCE: '/audiences/save/',
	DELETE_AUDIENCES: '/audiences/delete/',

	RETRIEVE_EXPERIENCES: '/experiences/list/',
	RETRIEVE_EXPERIENCE: '/experiences/get/',
	SEARCH_EXPERIENCES: '/experiences/search/',
	SAVE_EXPERIENCE: '/experiences/save/',
	DELETE_EXPERIENCES: '/experiences/delete/',

	RETRIEVE_METRICS: '/metrics/list/',
	RETRIEVE_METRIC: '/metrics/get/',
	SEARCH_METRICS: '/metrics/search/',
	SAVE_METRIC: '/metrics/save/',
	DELETE_METRICS: '/metrics/delete/',

	RETRIEVE_CONTENT_LIST: '/content/list/',
	RETRIEVE_CONTENT: '/content/get/',
	SEARCH_CONTENT: '/content/search/',
	SAVE_CONTENT: '/content/save/',
	DELETE_CONTENT: '/content/delete/',

	RETRIEVE_CHANNELS_LIST: '/channels/list/',
	RETRIEVE_CHANNEL: '/channels/get/',
	SAVE_CHANNEL: '/channels/save/',
	SAVE_CONTENT_TO_CHANNEL: '/channels/content/save/',
	DELETE_CHANNELS: '/channels/delete/',

	RETRIEVE_CHANNEL_DIRECT_EVENT_METRICS: '/metrics/direct-events/channel/',
	RETRIEVE_CHANNEL_INDIRECT_EVENT_METRICS: '/metrics/indirect-events/channel/',
	RETRIEVE_CHANNEL_INDIRECT_EVENT_METRICS_WITH_VALUE: '/metrics/indirect-event-value/channel/',
	RETRIEVE_CHANNEL_ENGAGEMENT_METRICS: '/metrics/engagements/channel/',
	RETRIEVE_CHANNEL_CONVERSION_METRICS: '/metrics/conversions/channel/',

	RETRIEVE_SITES: '/sites/list/',
	SAVE_SITE: '/sites/save/',
	DELETE_SITES: '/sites/delete/',

	RETRIEVE_EVENT_TYPES: '/event-types/list/',
	SAVE_EVENT_TYPE: '/event-types/save/',
	DELETE_EVENT_TYPES: '/event-types/delete/'

	// DRUID_BASE_URL: 'https://localhost:5050'
	
}

export default SphereAdminServices;
