/*
 * (c) Verra Technology Corporation
 */

import React, { Component } from 'react';
import MetricEventTypes from '../../../model/MetricEventTypes';
import Optimization from '../../../model/Optimization';
import PublishableObject from '../../../model/PublishableObject.mjs';
import AddMetricsToOptimiztionCommand from '../../commands/AddMetricsToOptimizationCommand';
import CreateMetricCommand from '../../commands/CreateMetricCommand';
import OpenModalCommand from '../../commands/OpenModalCommand';
import SphereAdminSession from '../../model/SphereAdminSession';
import DataGrid from '../controls/DataGrid';
import Hint from '../controls/Hint';
import SelectMetricModal from '../modals/SelectMetricModal';

//

/**
 * The MetricsTab contains the UI for assigning and managing metrics for an Optimization
 */
class MetricsTab extends Component {
	
	/**
	 * Constructs the panel.
	 */
	 constructor() {
		super();

		this.state = {
			itemsChecked: false
		};

		this.gridRef = React.createRef();
	}

	/**
	 * Renders the component
	 * @see react docs
	 */
	render() {
		return 	<div>
					{ this.#getMetricsMarkup() }
				</div>;
	}

	/**
	 * @return The markup for managing Metrics
	 */
	#getMetricsMarkup() {
		const optimization = SphereAdminSession.optimization;
		const isPublished = ( optimization.status === PublishableObject.PUBLISHED );
		const isComplete = ( optimization.status === Optimization.COMPLETE );
		const selectedMetric = optimization.metrics.find( metric => metric.primary );
		
		const metricsToolTip = 'Choose the metrics in which to measure success for the Optimization';
		const canEditMsg = ( !isPublished ) ? 'Use the buttons to the right to create and assign metrics.' : '';
		const noMetricsMsg = <div className='grid-cell default-100'>No metrics have been assigned to the Optimization, you must add at least one primary metric. { canEditMsg }</div>;
		const buttonsDisabled = ( optimization.locked ) ? 'disabled' : '';
		const removeDisabled = ( !optimization.locked && this.state.itemsChecked ) ? '' : 'disabled';
		
		const headers = [
			{ label: 'Name', field: 'name', width: 25 },
			{ 
				label: 'Conversion',
				field: ( metric ) => {
					const type = MetricEventTypes.conversionTypes.find( type => type.eventId === metric.conversionEventId );
					const freqency = MetricEventTypes.eventFreqencies.find( frequency => frequency.value === metric.conversionEventFrequency );
					const eventName = ( type != null ) ? type.name : metric.conversionEventId;
					return `${ eventName }, ${ freqency.name }`;
				}, 
				width: 25 
			},
			{ 
				label: 'Engagement', 
				field: ( metric ) => {
					const type = MetricEventTypes.engagementTypes.find( type => type.eventId === metric.engagementEventId );
					const freqency = MetricEventTypes.eventFreqencies.find( frequency => frequency.value === metric.engagementEventFrequency );
					const eventName = ( type != null ) ? type.name : metric.conversionEventId;
					return `${ eventName }, ${ freqency.name }`;
				}, 
				width: 25
			}
		];

		return <div>
					<div className='content-panel'>
						<div className='grid panel-cell'>
							<div className='grid-cell default-60'>
								<h3>Metrics <Hint width='250px' content={ metricsToolTip }/></h3>
							</div>
							{ ( !isPublished && !isComplete ) &&
								<div className='grid-cell default-40 align-right'>
									<button className={ 'button ' + buttonsDisabled } onClick={ this.#handleCreateNewMetric.bind( this ) }>Create</button>
									<button className={ 'button control-pad-left ' + buttonsDisabled } onClick={ this.#handleAddMetric.bind( this ) }>Add</button>
									<button className={ 'button control-pad-left ' + removeDisabled }  onClick={ this.#handleRemoveMetric.bind( this ) }>Remove</button>
								</div>
							}
						</div>
					</div>
					<DataGrid 
						ref={ this.gridRef } 
						headers={ headers } 
						data={ optimization.metrics } 
						idField='id' 
						checkColumn={ true } 
						checkOnRowClick={ true }
						radioColumn={ true }
						radioLabel='Primary'
						selected={ selectedMetric }
						showHeaderIfEmpty={ false } 
						statusIndicator={ false } 
						disabled={ optimization.locked || isPublished || isComplete } 
						maxBodyHeight='300px'
						noContent={ noMetricsMsg }
						checkBoxChangeHandler={ this.#handleGridCheckBoxSelect.bind( this )}
						radioChangeHandler={ this.#handlePrimaryMetricSelected.bind( this )}
					/>
				</div>;
	}

	//

	/**
	 * Handles clicks on the new Channel button
	 */
	#handleCreateNewMetric( e ) {
		const createMetric = new CreateMetricCommand( true, this.#handleMetricCreatedAndAdded.bind( this ));
		createMetric.execute();
	};

	/**
	 * Handles selection of Audiences in the SelectAudienceModal
	 */
	#handleMetricCreatedAndAdded() {
		const metrics = SphereAdminSession.optimization.metrics;
		if( metrics.length === 1 ) metrics[ 0 ].primary = true;
		this.props.changeHandler();
		this.setState({});
	}

	/**
	 * Handles a click to add an existing Metric
	 */
	#handleAddMetric() {
		const selectMetricModal = <SelectMetricModal selectHandler={ this.#handleMetricSelectedForAdd.bind( this )}/>;
		const openModal = new OpenModalCommand( 'Select Metric', selectMetricModal, '700px', false );
		openModal.execute();
	}

	/**
	 * Handles selection of Metrics in the SelectMetricModal
	 */
	#handleMetricSelectedForAdd( selectedMetrics ) {
		const addMetrics = new AddMetricsToOptimiztionCommand( selectedMetrics );
		addMetrics.execute();
		this.gridRef.current.unCheckAll();
		this.props.changeHandler();
		this.setState({});
	}

	/**
	 * Handles clicks on a checkbox in the Content grid
	 */
	#handleGridCheckBoxSelect( grid ) {
		const checked = grid.getChecked();
		this.setState({ itemsChecked: ( checked.length > 0 )});
	}

	/**
	 * Handles a click on a radio button in the grid, setting the Metric as primary
	 */
	#handlePrimaryMetricSelected( grid ) {
		const selectedMetricId = grid.getSelected();
		SphereAdminSession.optimization.metrics.forEach( metric => {
			metric.primary = ( metric.id === selectedMetricId );
		});
		this.setState({});
	}

	/**
	 * Handles the click to remove and metric from the optimization
	 */
	#handleRemoveMetric() {
		const selectedMetrics = this.gridRef.current.getChecked();
		const optimization = SphereAdminSession.optimization;
		let primaryRemoved = false;
		
		optimization.metrics = optimization.metrics.filter(( metric ) => {
			const notRemoved = ( selectedMetrics.find( selectedMetric => metric === selectedMetric ) == null );
			primaryRemoved = ( !notRemoved && metric.primary );
			return notRemoved;
		});

		if( primaryRemoved && optimization.metrics.length >  0 ) {
			optimization.metrics[ 0 ].primary = true;
		}

		this.gridRef.current.unCheckAll();
		this.props.changeHandler();
		this.setState({ itemsChecked: false });
	}

}

//

export default MetricsTab;
