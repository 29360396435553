/*
 * (c) Verra Technology Corporation
 */

import React, { Component } from 'react';
import SetStateCommand from '../commands/SetStateCommand';
import SignOutUserCommand from '../commands/SignOutUserCommand';
import AdminStates from '../model/AdminStates';

/**
 * Renders the Header for the administration app
 */
class Header extends Component {
	
	/**
	 * Renders the component
	 * @see react docs
	 */
	render() {
		
		// const accounts = SphereAdminSession.accounts;
		//const selectedAccount = SphereAdminSession.selectedAccount;
		// const sites = ( selectedAccount != null ) ? selectedAccount.sites : null;
		// const selectedSite = ( sites != null && sites.length > 0 ) ? sites[ 0 ] : null;
		// var changeHandler = ( account ) => { handleAccountChange.apply( this, [ account ]); };

		/*
		var sitesMarkup = ( sites == null || sites.length === 0 ) ? '' :
			<DropDownField 
				label='Site'
				labelField='name' 
				labelAlignRight={true} 
				alignRight={true} 
				items={sites} 
				selectedItem={selectedSite} 
				hideBackground={true} 
				changeHandler={changeHandler}/>;
		

		var accountsMarkup = ( accounts < 2 ) ? '' : 
			<DropDownField 
				label='Account'
				labelField='name' 
				labelAlignRight={true} 
				alignRight={true} 
				items={accounts} 
				selectedItem={selectedAccount} 
				hideBackground={true} 
				changeHandler={changeHandler}/>;

		*/
		

		return ( 
			<div className='grid header'>
				<h1 className='grid-cell logo'>verra</h1>
				<div className='align-right controls'>
					{/* 
					<button className='align-right link-button control-pad-right' onClick={ this.#handleViewDocs.bind( this ) }>Documentation</button>
					<span className='control-pad-right' style={{color:'#666666'}}>|</span>
					*/}
					<button className='align-right link-button control-pad-right' onClick={ this.#handleSignOut.bind( this ) }>Sign Out</button>
					{/* <a href='mailto:support@verra.ai' className='align-right link-button control-pad-right'>Support</a> */}
				</div>
			</div> 
		);
	}

	// Private

	/**
	 * Handles changes to the name input field
	 */
	#handleSignOut(){
		const signOut = new SignOutUserCommand();
		signOut.execute();
	}
	
	/**
	 * Handles changes to the name input field
	 */
	#handleViewDocs(){
		const setState = new SetStateCommand( AdminStates.ADMIN_DOCUMENTATION );
		setState.execute();
	}
	
}

export default Header;
