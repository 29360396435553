/*
 * (c) Verra Technology Corporation
 */

import SphereAdminServices from "../../model/SphereAdminServices";
import SphereAdminSession from "../../model/SphereAdminSession";
import ServiceRequestCommand2 from "../ServiceRequestCommand2";



/**
 * Retrieves the currently logged in user's info
 */
class RetrieveUserRequest extends ServiceRequestCommand2 {

	halt = true;

	/**
	 * @return The service endpoint URL part. This is appended to SphereAdminServices.BASE_URL
	 */
	getService() {
		return SphereAdminServices.RETRIEVE_USER;
	}

   /**
	* Handles completion of the command
	*/
	handleComplete() {
		var response = this.getResponse();
		// console.info( 'RetrieveUserRequest::handleComplete', response );
		if( response.success ) {
			SphereAdminSession.user = response.result;
		}
	}
 
}

export default RetrieveUserRequest;
