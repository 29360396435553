/*
 * (c) Verra Technology Corporation
 */

import React, { Component } from 'react';
import MetricEventTypes from '../../model/MetricEventTypes';
import Optimization from '../../model/Optimization';
import PublishableObject from '../../model/PublishableObject.mjs';
import CreateAudienceCommand from '../commands/CreateAudienceCommand';
import CreateExperienceCommand from '../commands/CreateExperienceCommand';
import CreateMetricCommand from '../commands/CreateMetricCommand';
import CreateOptimizationCommand from '../commands/CreateOptimizationCommand';
import EditOptimizationCommand from '../commands/EditOptimizationCommand';
import SetStateCommand from '../commands/SetStateCommand';
import ViewOptimizationCommand from '../commands/ViewOptimizationCommand';
import ItemListPanel from '../components/controls/ItemListPanel';
import LargePencilIcon from '../icons/LargePencilIcon';
import LayersIcon from '../icons/LayersIcon';
import PerformanceIcon from '../icons/PerformanceIcon';
import UserIcon from '../icons/UserIcon';
import AdminStates from '../model/AdminStates';
import ChartTimeValues from '../model/ChartTimeValues';
import SphereAdminServices from '../model/SphereAdminServices';

//

/**
 * Defines the model type labels
 */
const modelTypes = {
	'1': 'A/B',
	'5': 'Personalization',
	'4': 'Predictive'
};

/**
 * The currently selected time period
 */
let selectedTimePeriod = ChartTimeValues.availableRanges[ 6 ];

/**
 * The dashboard analytics
 */
let analytics;

//

/**
 * The administration dashboard
 */
class DashboardPanel extends Component {
	
	/**
	 * Constructs the Component
	 */
	constructor() {
		super();
		const engagementMetricTypes = MetricEventTypes.engagementTypes;
		const conversionMetricTypes = MetricEventTypes.conversionTypes;
		this.state = {
			selectedOptimization: null
		};
	}

	/**
	 * Renders the component
	 * @see react docs
	 */
	render() {
		return ( 
			<div className='content-panel'>
				<h2>Dashboard</h2>
				{ this.#getOptimizationsMarkup() }	
				{ this.#getGettingStartedMarkup() }
				{ this.#getTasksMarkup() }
			</div>
		);
	}

	/**
	 * @return recent optimizations
	 */
	#getOptimizationsMarkup() {
		const noItemsContent = <div>No Optimizations Found. <button className='link-button' onClick={ this.#handleCreateOptimization.bind( this )}>Create Optimization</button></div>;
		const headers = [ 
			{ label: 'Name', field: 'name', width: '50' }, 
			{ label: 'Type', field: ( item ) => modelTypes[ item.type ], width: '20' },
			{ label: 'Last Modified', field: ( item ) => new Date( item.modifiedDate ).toLocaleDateString(), width: '20' }
		];
		return (
			<div style={{ marginTop: 18 }}>
				<h3>Recent Optimizations</h3>
				<div style={{ display: 'flex', gap: 13, marginTop: -10 }}>
					<div className='panel-cell' style={{ flexBasis: 0, flexGrow: 1 }}>
						<div style={{ margin: '-31px -18px -18px -18px' }}>
							<ItemListPanel
								headers={ headers }
								showControls={ false }
								showSearch={ false }
								showPaging={ false }
								singleSelect={ true }
								retrieveOnLoad={ true }
								noItemsContent={ noItemsContent }
								createCommand={ CreateOptimizationCommand }
								listEndPoint={ SphereAdminServices.RETRIEVE_OPTIMIZATIONS }
								clickHandler={ this.#handleOptimizationSelected.bind( this )}/>
						</div>
					</div>
				</div>
			</div>
		);
	}

	/**
	 * @return the getting started markup
	 */
	#getGettingStartedMarkup() {
		return (
			<div style={{ display: 'flex', gap: 13 }}>
				<div className='panel-cell' style={{ flexBasis: 0, flexGrow: 1 }}>
					<h3>Getting Started</h3>
					<p>Welcome to Verra! If you're new to the platform we suggest getting started with the following videos.</p>
					<iframe 
						src="https://www.youtube.com/embed/bhiR4A4zMLg" 
						title="Introduction to Verra" 
						frameBorder="0" 
						allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
						allowFullScreen>
					</iframe>
				</div>
				<div className='panel-cell' style={{ flexBasis: 0, flexGrow: 1 }}>
					<h3>Documentation</h3>
					<ul style={{ marginBottom: 0 }}>
						<li><a className='link-button' href='https://docs.verra.ai/'>Introduction</a></li>
						<li><a className='link-button' href='https://docs.verra.ai/administration/optimizations'>Optimizations</a></li>
						<li><a className='link-button' href='https://docs.verra.ai/administration/experiences'>Experiences</a></li>
						<li><a className='link-button' href='https://docs.verra.ai/administration/audiences'>Audiences</a></li>
						<li><a className='link-button' href='https://docs.verra.ai/administration/metrics'>Metrics</a></li>
						<li><a className='link-button' href='https://docs.verra.ai/administration/metrics'>Setting up your site</a></li>
					</ul>
				</div>
			</div>
		);
	}

	/**
	 * @return the markup for common tasks
	 */
	#getTasksMarkup() {
		return (
				<div style={{ display: 'flex', gap: 13 }}>
					<div className='panel-cell' style={{ flexBasis: 0, flexGrow: 1 }}>
						<h3>Common Tasks</h3>
						<div style={{ display: 'flex', gap: 13, alignItems: 'flex-end', justifyContent: 'center' }}>
							<button className='dashboard-task-icon' onClick={() => this.#handleCreateOptimization() }>
								<div className='icon'>
									<LargePencilIcon size={ 46 }/>
								</div>
								<div className='label'>Create Optimization</div>
							</button>
							<button className='dashboard-task-icon' onClick={() => this.#handleTask( AdminStates.ADMIN_OPTIMIZATIONS )}>
								<div className='icon'>
									<PerformanceIcon size={ 54 }/>
								</div>
								<div className='label'>View Optimizations</div>
							</button>
							<button className='dashboard-task-icon pad-control-left' onClick={() => this.#handleCreateExperience() }>
								<div className='icon'>
									<LayersIcon size={ 46 }/>
								</div>
								<div className='label'>Create Experience</div>
							</button>
							<button className='dashboard-task-icon pad-control-left' onClick={() => this.#handleTask( AdminStates.ADMIN_AUDIENCES )}>
								<div className='icon'>
									<UserIcon size={ 54 }/>
								</div>
								<div className='label'>Manage Audiences</div>
							</button>
						</div>
					</div>
					<div className='panel-cell' style={{ flexBasis: 0, flexGrow: 1 }}>
						<h3>Other Tasks</h3>
						<ul style={{ marginBottom: 0 }}>
							<li><button className='link-button' onClick={() => this.#handleTask( AdminStates.ADMIN_EXPERIENCES )}>View Experiences</button></li>
							<li><button className='link-button' onClick={() => this.#handleTask( AdminStates.ADMIN_AUDIENCES )}>View Audiences</button></li>
							<li><button className='link-button' onClick={() => this.#handleCreateAudience() }>Create Audience</button></li>
							<li><button className='link-button' onClick={() => this.#handleTask( AdminStates.ADMIN_METRICS )}>View Metrics</button></li>
							<li><button className='link-button' onClick={() => this.#handleCreateMetric() }>Create Metric</button></li>
							<li><button className='link-button' onClick={() => this.#handleTask( AdminStates.ADMIN_CONFIGURATION_SITES )}>Manage Sites</button></li>
						</ul>
					</div>
				</div>
		);
	}

	//

	/**
	 * Handles clicks on items in the optimizations list
	 */
	#handleOptimizationSelected( item ) {
		if( item.status === PublishableObject.PUBLISHED || item.status === Optimization.COMPLETE ) {
			const viewOptimization = new ViewOptimizationCommand( item.id );
			viewOptimization.execute();
		} else {
			const editOptimization = new EditOptimizationCommand( item.id );
			editOptimization.execute();
		}
	}

	//
	
	/**
	 * Handles the click to create a new optimization
	 */
	#handleCreateOptimization() {
		const create = new CreateOptimizationCommand();
		create.execute();
	}

	/**
	 * Handles the click to create a new experience
	 */
	#handleCreateExperience() {
		const create = new CreateExperienceCommand();
		create.execute();
	}

	/**
	 * Handles a click to create an audience
	 */
	#handleCreateAudience() {
		const createAudience = new CreateAudienceCommand();
		createAudience.execute();
	};

	/**
	 * Handles the click to create a new metric
	 */
	#handleCreateMetric( e ){
		const create = new CreateMetricCommand();
		create.execute();
	}

	/**
	 * Handles clicks on the tasks buttons / links
	 */
	#handleTask( state ) {
		var setState = new SetStateCommand( state );
		setState.execute();
	}

}

export default DashboardPanel;
