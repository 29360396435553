/*
 * (c) Verra Technology Corporation
 */

import React, { Component } from 'react';
import ManageSubscriptionCommand from '../commands/ManageSubscriptionCommand';
import UpSellComponent from '../components/UpSellComponent';
import SphereAdminSession from '../model/SphereAdminSession';

//

/**
 * UI for managing the user's account
 */
class ManageAccountPanel extends Component {

	/**
	 * Constructs the Component
	 */
	constructor(){
		super();
	}
	
	/**
	 * Renders the component
	 * @see react docs
	 */
	render() {	
		return (
			<div className='content-panel'>
				<h2>Manage Account</h2>
				{ this.#getSubscriptionMarkup() }
			</div>
		);
	}

	/**
	 * @return the markup for common tasks
	 */
	#getSubscriptionMarkup() {
		const hasPaidAccount = ( SphereAdminSession.selectedAccount.customerId != null );
		return (
				<div style={{ display: 'flex', gap: 13 }}>
					<div className='panel-cell' style={{ flexBasis: 0, flexGrow: 4 }}>
						<h3>Recommended</h3>
						<UpSellComponent isModal={ false } message='Upgrade your plan'/>
					</div>
					<div className='panel-cell' style={{ flexBasis: 0, flexGrow: 3 }}>
						<h3>Other Tasks</h3>
						<ul style={{ marginBottom: 0 }}>
							{ hasPaidAccount && 
								<li><button className='link-button' onClick={ this.#handleManageSubscription.bind( this )}>Manage Subscription</button></li>
							}
							{/* <li><button className='link-button' onClick={ this.#handleDeleteAccount.bind( this )}>Delete Account</button></li> */}
						</ul>
						<h3 style={{ marginTop: 18 }}>Support</h3>
						<ul style={{ marginBottom: 0 }}>
							<li><a className='link-button' href='https://docs.verra.ai/' target='_blank'>Documentation</a></li>
						</ul>
					</div>
				</div>
		);
	}

	/**
	 * Handles a click to manage a customer's subscription through Stripe
	 */
	#handleManageSubscription() {
		const manage = new ManageSubscriptionCommand();
		manage.execute();
	}

	/**
	 * Handles a request to delete an account
	 */
	// #handleDeleteAccount() {
		
	// }

}

//

export default ManageAccountPanel;
