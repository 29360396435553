/*
 * (c) Verra Technology Corporation
 */

import Command from '../../commands/Command.mjs';
import CreateMetricModal from '../components/modals/CreateMetricModal';
import UpSellComponent from '../components/UpSellComponent';
import PermissionsManager from '../managers/PermissionsManager';
import AdminStates from '../model/AdminStates';
import Permissions from '../model/Permissions';
import OpenModalCommand from './OpenModalCommand';
import SetStateCommand from './SetStateCommand';

/**
 * Initiates the view to create an Metric
 */
class CreateMetricCommand extends Command {
	
	#modal;
	#createdHandler;
	
	//

	/**
	 * Constructs the CreateMetricCommand
	 * @param modal Whether or not the creation is done as a modal or not
	 */
	constructor( modal = false, createdHandler ) {
		super();
		this.#modal = modal;
		this.#createdHandler = createdHandler;
	}

	/**
	 * Handles execution of the command
	 */
	handleExecute() {
		const hasPermission = PermissionsManager.hasPermissionForMore( Permissions.METRICS_MAX );
		if( hasPermission.allowed ) {
			if( this.#modal ) {
				const createModal = <CreateMetricModal createdHandler={ this.#createdHandler }/>;
				const openModal = new OpenModalCommand( 'Create Metric', createModal, '70%', false );
				openModal.execute();
			} else {
				const setState = new SetStateCommand( AdminStates.ADMIN_METRICS_CREATE );
				setState.execute();
			}
		} else {
			const msg = <p style={{ margin: 0 }}>You've reached your maximum number of allowed Metrics. Your account only allows { hasPermission.permitted }.</p>;
			const upSell = <UpSellComponent message={ msg } callout='metrics'/>;
			const openModal = new OpenModalCommand(  'Limit Reached', upSell, '600px', true );
			openModal.execute();
		}
	}
	
}

//

export default CreateMetricCommand;
