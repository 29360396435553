/*
 * (c) Verra Technology Corporation
 */

import ManageSubscriptionCommand from "../commands/ManageSubscriptionCommand";
import ViewOnboardingWizardCommand from "../commands/ViewOnboardingWizardCommand";

/**
 * Maps commands defined in AdminConfigData.json to Command classes. This ensures the Command class is 
 * built into the code.
 */
const StateCommandMap = {

	ViewOnboardingWizardCommand: ViewOnboardingWizardCommand,
	ManageSubscriptionCommand: ManageSubscriptionCommand,

};

export default StateCommandMap;