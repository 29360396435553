/*
 * (c) Verra Technology Corporation
 */

import SphereAdminServices from '../../model/SphereAdminServices';
import ServiceRequestCommand from '../ServiceRequestCommand';

/**
 * Adds an Experience to an Optimization
 */
class AddExperienceToOptimizationRequest extends ServiceRequestCommand {

	#experience;
	#optimizationId;
	#audienceId;

	/**
	 * Constructs the Request
	 * @param optimizationId The ID of the Optimization to add the Experience to
	 * @param experience The Experience to add
	 * @param audienceId The audience ID for personalizations, optional
	 */
	constructor( experience, optimizationId, audienceId ){
		super();
		this.#experience = experience;
		this.#optimizationId = optimizationId;
		this.#audienceId = audienceId;
	}

	/**
	 * @return The service endpoint URL part. This is appended to SphereAdminServices.BASE_URL
	 */
    getService() {
		return SphereAdminServices.ADD_EXPERIENCE_TO_OPTIMIZATION;
	}

	/**
	 * @return The parameters to send with the request
	 */
	getParams() {
		return { accountId: this.#experience.accountId, experienceId: this.#experience.id, optimizationId: this.#optimizationId, audienceId: this.#audienceId };
	}

   /**
	* Handles completion of the command
	*/
	// handleComplete() {
		// const response = this.getResponse();
		// if( response.success ) {
		// 	// TODO: handle error
		// }
		//console.info( 'SaveContentRequest::handleComplete content', this.content );
	// }
 
}

export default AddExperienceToOptimizationRequest;
