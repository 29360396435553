/*
 * (c) Verra Technology Corporation
 */

import React, { Component } from 'react';
import ExperienceModificationType from '../../../model/ExperienceModificationType';
import ModifiableObject from '../../../model/ModifiableObject.mjs';
import ElementUtil from '../../../util/ElementUtil';
import SaveExperienceToStorageCommand from '../../commands/SaveExperienceToStorageCommand';
import AdminStates from '../../model/AdminStates';
import SphereAdminSession from '../../model/SphereAdminSession';
import InputField from '../controls/InputField';

//

/**
 * The SiteExperienceEditor displays the site, modifications to the site, and allows for the selection of elements on the site
 */
class SiteExperienceEditor extends Component {
	
	#iFrameContainerRef;
	#iFrameRef;
	#highlightedElement;
	#hoverHighlightRef;
	#selectedHighlightsContainerRef
	#modificationApplicationMap;

	//

	/**
	 * Constructs the panel.
	 */
	constructor() {
		super();
		this.state = {
			sitePath: '',
			frameLoaded: false,
			primaryElement: null,
			selectedElements: [],
		};

		this.#iFrameContainerRef = React.createRef();
		this.#iFrameRef = React.createRef();
		this.#hoverHighlightRef = React.createRef();
		this.#selectedHighlightsContainerRef = React.createRef();

		if( window.location !== window.parent.location ){
			window.addEventListener( 'message', this.#handleWindowMessage.bind( this ));
			window.parent.postMessage({ action: 'GET_SITE' }, '*' );
		}

		this.#modificationApplicationMap = {};
		this.#modificationApplicationMap[ ExperienceModificationType.CSS ] = this.#applyCssModification.bind( this );
		this.#modificationApplicationMap[ ExperienceModificationType.JS ] = this.#applyJsModification.bind( this );
		this.#modificationApplicationMap[ ExperienceModificationType.TEXT ] = this.#applyTextModification.bind( this );
		this.#modificationApplicationMap[ ExperienceModificationType.MARKUP ] = this.#applyMarkupModification.bind( this );
		this.#modificationApplicationMap[ ExperienceModificationType.STYLES ] = this.#applyStylesModification.bind( this );
		this.#modificationApplicationMap[ ExperienceModificationType.ADD_BEFORE ] = this.#applyAddElementModification.bind( this );
		this.#modificationApplicationMap[ ExperienceModificationType.ADD_AFTER ] = this.#applyAddElementModification.bind( this );
		this.#modificationApplicationMap[ ExperienceModificationType.REMOVE ] = this.#applyRemoveModification.bind( this );
	}

	/**
	 * Handles the mounting of the component
	 */
	// componentDidUpdate() {
	// 	console.info( 'SiteExperienceEditor::componentDidUpdate', this.props.selectedModification );
	// }
	
	// Rendering

	/**
	 * Renders the component
	 * @see react docs
	 */
	render() {
		return (
			<div>
				{ this.#getFrameMarkup() }
			</div>
		);
	}

	/**
	 * @return The markup for displaying secondary fields
	 */
	#getFrameMarkup() {
		const previewEnabled = ( this.props.modificationsEnabled );
		const hightlightDisplay = ( previewEnabled && this.#highlightedElement != null ) ? 'block' : 'none';
		const selectedDisplay = ( previewEnabled && this.state.selectedElements?.length > 0 ) ? 'block' : 'none';
		const siteBaseUrl = ( this.props.selectedSite != null ) ? this.props.selectedSite.url : '';
		const path = siteBaseUrl + this.state.sitePath;
		const frameDisplay = ( this.state.frameLoaded ) ? 'block' : 'none';

		return (
			<div className='panel-cell pad-cell-left' style={{ display: frameDisplay }}>
				<div className='grid pad-cell-bottom'>
					<div className='grid-cell default-90'>
						<InputField value={ path } onChange={ value => this.state.sitePath = value }/>
					</div>
					<div className='grid-cell default-10 pad-cell-left'>
						<button style={{ width: '100%' }} className={ 'button' } onClick={ this.#handleNavigateToPage.bind( this )}>Go</button>
					</div>
				</div>
				<div ref={ this.#iFrameContainerRef } className='no-select' style={{ position: 'relative', overflow: 'hidden' }}>
					<div ref={ this.#hoverHighlightRef } className='hover-highlight' style={{ display: hightlightDisplay }}></div>
					<div ref={ this.#selectedHighlightsContainerRef } style={{ display: selectedDisplay }}></div>
					<iframe
						ref={ this.#iFrameRef }
						width='100%'
						onLoad={ this.#handleIFrameLoaded.bind( this )}
						onMouseUp={ this.upListener }
						// onMouseOut={ this.#stopResize.bind( this )}
					/>
				</div>
			</div>
		);
	}

	// Navigation

	/**
	 * Handles a selection of a site from the Sites drop down
	 */
	#handleNavigateToPage() {
		this.#navigateToPage( this.props.selectedSite, this.state.sitePath );
	}

	/**
	 * Navigates the iframe to a page
	 */
	#navigateToPage( site, path ) {
		const mode = ( SphereAdminSession.currentState === AdminStates.ADMIN_EXPERIENCES_EDIT ) ? 'edit' : 'create';
		let url = `https://${ site.url }${ path }?verra-edit-mode=${ mode }&verra-site-id=${ site.id }&verra-experience-id=${ SphereAdminSession.experience.id }&verra-id=${ SphereAdminSession.token }`;
		if( SphereAdminSession.optimizationId != null ) url += `&verra-optimization-id=${ SphereAdminSession.optimizationId }`;
		// console.info( 'navigate', SphereAdminSession.experience.id );
		this.#saveToStorage();
		window.parent.postMessage({ action: 'NAVIGATE', url: url }, '*' );
	}

	/**
	 * Saves the experience to session storage so it can be retrieved without having been saved
	 */
	#saveToStorage() {
		const save = new SaveExperienceToStorageCommand( SphereAdminSession.experience );
		save.execute();
	}

	// iframe events

	/**
	 * Handles iframe post message events
	 */
	#handleWindowMessage( event ) {
		// console.info( 'handleWindowMessage' ); //, event.data );
		if( event.data.action === 'SET_SITE' && this.#iFrameRef.current != null ){
			this.#setSite( event.data.siteId, event.data.siteContent, event.data.path );
		}
	}

	/**
	 * Handles ...
	 */
	#setSite( siteId, siteContent, path ) {
		this.#iFrameRef.current.contentWindow.document.open();
		this.#iFrameRef.current.contentWindow.document.write( siteContent );
		this.#iFrameRef.current.contentWindow.document.close();

		// SphereAdminSession.experience.siteId = Number( siteId );
		// const sites = SphereAdminSession.sites;
		// const selectedSite = sites.find( site => site.id.toString() === siteId.toString() );
		// this.setState({ path, selectedSite });

		this.setState({ path });
	}

	/**
	 * Handles the load event from the iframe
	 */
	#handleIFrameLoaded( event ) {
		const experience = SphereAdminSession.experience;
		const isLocked = ( experience.status === ModifiableObject.LOCKED );
		const iframe = this.#iFrameRef.current;

		if( !isLocked ) {
			iframe.contentWindow.document.body.addEventListener( 'mouseover', this.#handleMouseOver.bind( this ));
			iframe.contentWindow.document.body.addEventListener( 'mouseout', this.#handleMouseOut.bind( this ));
		}
		
		iframe.contentWindow.document.body.addEventListener( 'click', this.#handleDocumentClick.bind( this ), { capture: true });
		iframe.contentWindow.addEventListener( 'scroll', this.#handleIFrameScroll.bind( this ));
		iframe.contentWindow.addEventListener( 'resize', this.#handleIFrameResize.bind( this ));

		// load the changes into the iframe
		// this.#handleTogglePreview( true );
		this.setState({ frameLoaded: true });
		this.props.loadedHandler();
	}

	/**
	 * Handles mouse over events in the iframe
	 */
	#handleMouseOver( e ) {
		e.preventDefault();
		e.stopPropagation(); 
		if( this.props.modificationsEnabled ) {
			this.#highlightedElement = e.target;
			const hoverHighlight = this.#hoverHighlightRef.current
			hoverHighlight.style.display = 'block';
			this.#positionHighlight( hoverHighlight, this.#highlightedElement );
		}
	}

	/**
	 * Handles mouse out events in the iframe
	 */
	#handleMouseOut( e ) {
		e.preventDefault();
		e.stopPropagation(); 
		const hoverHighlight = this.#hoverHighlightRef.current;
		hoverHighlight.style.display = 'none';
		this.#highlightedElement = null;
	}

	/**
	 * Handles all clicks within the document
	 */
	#handleDocumentClick( e ) {
		e.preventDefault();
		e.stopPropagation(); 
		
		const experience = SphereAdminSession.experience;
		const isLocked = ( experience.status === ModifiableObject.LOCKED );

		const a = e.target.closest( 'a' );
		if( a != null ) this.state.sitePath = a.href.split( window.location.origin )[ 1 ];

		if( this.props.modificationsEnabled && !isLocked ) {
			this.state.selectedElements = null;
			this.props.elementClickedHandler( ( this.state.primaryElement != e.target ) ? e.target : null );
			const selected = ( this.state.primaryElement != e.target ) ? [ e.target ] : null;
			this.selectElements( selected );
		} else {
			// put this in an else as selectElements will set the state and we don't need to call it twice
			// otherwise we still need to update the site url
			this.setState({});
		}
	}

	/**
	 * Handles the scroll event from the iframe
	 */
	#handleIFrameScroll( e ) {
		this.#updateHighlightPositions();
	}

	/**
	 * Handles the resize event from the iframe
	 */
	#handleIFrameResize( e ) {
		this.#updateHighlightPositions();
	}

	// Element highlighting
	
	/**
	 * Updates the highlight positions
	 */
	#updateHighlightPositions() {
		if( this.#highlightedElement != null ) {
			this.#positionHighlight( this.#hoverHighlightRef.current, this.#highlightedElement );
		}
		if( this.state.selectedElements != null ){
			const highlights = document.querySelectorAll( '.selected-highlight' );
			highlights.forEach(( highlight, i ) => {
				this.#positionHighlight( highlight, this.state.selectedElements[ i ]);
			});
		}
	}

	/**
	 * Positions the element highlight
	 * @param highlight The highlight element, either for the moused over element or selected element
	 * @param elementToHighlight The element within the iframe to hightlight
	 */
	#positionHighlight( highlight, elementToHighlight ) {
		const elementRect = elementToHighlight.getBoundingClientRect();
		highlight.style.top = ( elementRect.y ) + 'px';
		highlight.style.left = ( elementRect.x ) + 'px';
		highlight.style.width = ( elementRect.width ) + 'px';
		highlight.style.height = ( elementRect.height ) + 'px';
	}

	// Modification applying and disabling

	/**
	 * Applies the global CSS modification
	 */
	#applyCssModification( modification, enabled ) {
		const iframe = this.#iFrameRef.current;
		let styleElement = iframe.contentWindow.document.getElementById( 'verra-global-css' );
		if( styleElement == null ) {
			styleElement = document.createElement( 'style' );
			styleElement.setAttribute( 'id', 'verra-global-css' );
			iframe.contentWindow.document.head.appendChild( styleElement );
		}
		styleElement.textContent = ( enabled ) ? modification.value : '';
	}

	/**
	 * Applies the global CSS modification
	 */
	#applyJsModification( modification, enabled ) {
		const iframe = this.#iFrameRef.current;
		let styleElement = iframe.contentWindow.document.getElementById( 'verra-global-js' );
		if( styleElement == null ) {
			styleElement = document.createElement( 'style' );
			styleElement.setAttribute( 'id', 'verra-global-js' );
			iframe.contentWindow.document.head.appendChild( styleElement );
		}
		styleElement.textContent = ( enabled ) ? modification.value : '';
	}

	/**
	 * Applies a text modification
	 */
	#applyTextModification( modification, enabled ) {
		const path = modification.currentPath;
		const elements = this.#iFrameRef.current.contentWindow.document.querySelectorAll( path );
		elements.forEach(( element, index ) => {
			element.textContent = ( enabled ) ? modification.value : modification.original[ index ];
		});
		this.#updateHighlightPositions();
	}

	/**
	 * Applies a markup modification
	 */
	#applyMarkupModification( modification, enabled, selected ) {
		const path = modification.currentPath;
		const elements = this.#iFrameRef.current.contentWindow.document.querySelectorAll( path );
		const newElements = [];
		elements.forEach(( element, index ) => {

			const template = document.createElement( 'template' );
			template.innerHTML = ( enabled ) ? modification.value : modification.original[ index ];
			const newElement = template.content.firstChild;

			element.replaceWith( newElement );
			if( enabled && selected ) newElements.push( newElement );
			if( index === 0 ) this.#updateMarkupModificationElementPath( modification, elements, newElement );
		});

		if( enabled && selected ) this.selectElements( newElements );
	}

	/**
	 * Updates the modifications current path
	 */
	#updateMarkupModificationElementPath( modification, elements, newElement ) {
		const originalNodeName = elements[ 0 ].nodeName.toLowerCase();
		const newNodeName = newElement.nodeName.toLowerCase();

		const rootNodeMatch = ( newNodeName === originalNodeName );
		const idMatch = ( newNodeName === originalNodeName );
		const willChangePath = ( !rootNodeMatch || !idMatch );

		if( willChangePath ) {
			if( elements.length === 1 ) {
				modification.currentPath = ElementUtil.getQuerySelectorPath( newElement );
			} else {
				modification.currentPath = modification.currentPath.replace( new RegExp( originalNodeName + '$' ), newNodeName );
				this.props.modificationPathChangedHandler( modification );
			}
		}
	}

	/**
	 * Applies a styles modification
	 */
	#applyStylesModification( modification, enabled ) {
		const styleTagId = modification.currentPath.replace( / /g, '---' ); // replace spaces with underscores
		const iframe = this.#iFrameRef.current;
		let styleElement = iframe.contentWindow.document.getElementById( styleTagId );
		if( styleElement == null ) {
			styleElement = document.createElement( 'style' );
			styleElement.setAttribute( 'id', styleTagId );
			iframe.contentWindow.document.head.appendChild( styleElement );
		}
		if( enabled ) {
			let styleContent = `${ modification.currentPath } {`;
			Object.keys( modification.value ).forEach( prop => {
				styleContent += `${ prop }:${ modification.value[ prop ] };`;
			});
			styleContent += '}';
			styleElement.textContent = styleContent;
		} else {
			styleElement.textContent = '';
		}

		this.#updateHighlightPositions();
	}

	/**
	 * Applies an add element modification
	 */
	#applyAddElementModification( modification, enabled, selected ) {
		const path = modification.currentPath;
		const elements = this.#iFrameRef.current.contentWindow.document.querySelectorAll( path );
		const newElements = [];

		// console.info( 'applyAddElementModification' );
		// console.info( modification );

		elements.forEach(( element, index ) => {
			if( enabled ) {

				const template = document.createElement( 'template' );
				template.innerHTML = modification.value;
				const newElement = template.content.firstChild;

				// the new element hasn't been added yet, let's insert it
				if( modification.original == null || element === modification.original?.[ index ] ) {
					if( modification.type === ExperienceModificationType.ADD_BEFORE ) {
						element.parentElement.insertBefore( newElement, element );
					} else {
						element.parentElement.insertBefore( newElement, element.nextElementSibling );
					}
					// update the relative element with an ID so we can easily reference it in the case of nth-of-type paths
					if( element.id == null || element.id === '' ) {
						element.id = `verra-${ window.crypto.randomUUID() }`;
					}
				} 

				// the new element already exists, replace it
				else {
					element.replaceWith( newElement );
				}

				if( enabled && selected ) newElements.push( newElement );
				if( index === 0 ) modification.currentPath = ElementUtil.getQuerySelectorPath( newElement );

			} else {
				// console.info( 'disabled remove' );
				element.remove();
				if( index === 0 && modification?.original?.[ 0 ] != null ) {
					modification.currentPath = ElementUtil.getQuerySelectorPath( modification.original[ 0 ] );
				} else {
					modification.currentPath = modification.path;
				}
			}
		});

		if( enabled && selected ) this.selectElements( newElements );
	}

	/**
	 * Applies a remove element modification
	 */
	#applyRemoveModification( modification, enabled ) {
		const path = modification.currentPath;
		const elements = this.#iFrameRef.current.contentWindow.document.querySelectorAll( path );
		elements.forEach(( element, index ) => {
			if( enabled ) {
				element.style.display = 'none';
			} else if( modification.original[ index ] != null && modification.original[ index ] !== '' ) {
				element.style.display = modification.original[ index ];
			} else {
				element.style.removeProperty( 'display' );
			}
		});
		this.#updateHighlightPositions();
	}

	// Public

	/**
	 * Handles window resize events
	 */
	setHeight( height ) {
		const iFrameContainer = this.#iFrameContainerRef.current;
		if( iFrameContainer != null ){
			iFrameContainer.style.maxHeight = height + 'px';
			iFrameContainer.style.height = height + 'px';
			this.#iFrameRef.current.style.height = height + 'px';
		}
	}

	/**
	 * @return the getBoundingClientRect of the components root element
	 */
	getBoundingClientRect() {
		return this.#iFrameContainerRef?.current.getBoundingClientRect();
	}

	/**
	 * Applies, or disables, a modification in the site editor
	 */
	applyModificationChange( modification, enabled, selected ) {
		this.#modificationApplicationMap[ modification.type ]( modification, enabled, selected );
	}

	/**
	 * Selects an element for editing and/or import
	 */
	selectElements( selectedElements ) {
		this.#selectedHighlightsContainerRef.current.innerHTML = '';
		let primaryElement;
		if( selectedElements != null ) {
			selectedElements.forEach(( element, index ) => {
				if( index === 0 ) primaryElement = element;
				const highlight = document.createElement( 'div' );
				highlight.className = 'selected-highlight';
				this.#selectedHighlightsContainerRef.current.appendChild( highlight );
				this.#positionHighlight( highlight, element ); 
			});
		}
		this.setState( { primaryElement, selectedElements } );
		this.props.elementsSelectedHandler( primaryElement, selectedElements );
	}
	
	/**
	 * Selects elements that match a modifications current path
	 */
	selectModificationElements( modification ) {
		const modElements = this.#iFrameRef.current.contentWindow.document.querySelectorAll( modification.currentPath );
		// console.info( 'selectModificationElements', modElements );
		this.selectElements( modElements );
	}
	
	/**
	 * Selects elements matching the path provided
	 */
	selectElementsByPath( path ) {
		const elements = this.#iFrameRef.current.contentWindow.document.querySelectorAll( path );
		this.selectElements( elements );
	}
	
}

//

export default SiteExperienceEditor;
