/*
 * (c) Verra Technology Corporation
 */

import Command from '../../commands/Command.mjs';
import AdminStates from '../model/AdminStates';
import SphereAdminSession from '../model/SphereAdminSession';
import SetStateCommand from './SetStateCommand.js';

/**
 * Command used to set the state of the admin
 */
class SignOutUserCommand extends Command {
	
	/**
	 * Handles execution of the command
	 */
	handleExecute() {
		SphereAdminSession.token = null;
		const setState = new SetStateCommand( AdminStates.NOT_AUTHENTICATED );
		setState.execute();
		window.location.href = '/';
	}
	
}

export default SignOutUserCommand;
