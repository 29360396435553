/*
 * (c) Verra Technology Corporation
 */

import React, { Component } from 'react';
import ExperienceModificationType from '../../../model/ExperienceModificationType';
import ModifiableObject from '../../../model/ModifiableObject.mjs';
import ArrowDownIcon from '../../icons/ArrowDownIcon';
import ArrowLeftIcon from '../../icons/ArrowLeftIcon';
import ArrowRightIcon from '../../icons/ArrowRightIcon';
import ArrowUpIcon from '../../icons/ArrowUpIcon';
import CodeIcon from '../../icons/CodeIcon';
import CopyIcon from '../../icons/CopyIcon';
import CssIcon from '../../icons/CssIcon';
import EditIcon from '../../icons/EditIcon';
import InsertAfterIcon from '../../icons/InsertAfterIcon';
import InsertBeforeIcon from '../../icons/InsertBeforeIcon';
import JavaScriptIcon from '../../icons/JavaScriptIcon';
import NotesIcon from '../../icons/NotesIcon';
import RemoveIcon from '../../icons/RemoveIcon';
import TextColorIcon from '../../icons/TextColorIcon';
import VisibilityIcon from '../../icons/VisibilityIcon';
import VisibilityOffIcon from '../../icons/VisibilityOffIcon';
import SphereAdminSession from '../../model/SphereAdminSession';

//

/**
 * The UI controls for editing Experiences
 */
class ExperienceEditorControls extends Component {
	
	#controlsContainerRef;
	#pathInputRef;
	
	//

	/**
	 * Constructs the panel.
	 */
	 constructor() {
		super();

		// this.state = { 
		// 	primaryElement: null,
		// 	selectedElements: null,
		// 	editingMode: null,
		// 	modificationsEnabled: true,
		// };

		this.#controlsContainerRef = React.createRef();
		this.#pathInputRef = React.createRef();
	}

	/**
	 * Renders the component
	 * @see react docs
	 */
	render() {
		return (
			<div>
				{ this.#getControlsMarkup() }
			</div>
		);
	}

	/**
	 * The markup for the experience controls
	 */
	#getControlsMarkup() {
		const experience = SphereAdminSession.experience;
		const isLocked = ( experience.status === ModifiableObject.LOCKED );

		const modType = ( this.props.selectedModification != null ) ? this.props.selectedModification.type : -1;
		const selectedElements = this.props.selectedElements;
		const primaryElement = this.props.primaryElement;

		const isSingleElement = ( selectedElements?.length === 1 );
		const previewEnabled = ( this.props.modificationsEnabled );

		const controlDisabled = ( selectedElements != null && selectedElements.length > 0 && previewEnabled ) ? '' : 'disabled';
		const globalBtnsDisabled = ( previewEnabled ) ? '' : 'disabled';

		const prevDisabled = ( previewEnabled && isSingleElement && primaryElement.previousElementSibling != null ) ? '' : 'disabled';
		const nextDisabled = ( previewEnabled && isSingleElement && primaryElement.nextElementSibling != null ) ? '' : 'disabled';
		const upDisabled = ( previewEnabled && isSingleElement && primaryElement.parentElement != null ) ? '' : 'disabled';
		const downDisabled = ( previewEnabled && isSingleElement && primaryElement.firstElementChild != null ) ? '' : 'disabled';
		const textDisabled = ( previewEnabled && primaryElement != null && primaryElement.firstElementChild == null && primaryElement.firstChild != null && primaryElement.firstChild.nodeType === 3 ) ? '' : 'disabled';
		const addElementDisabled = ( selectedElements != null && selectedElements.length === 1 && previewEnabled ) ? '' : 'disabled';

		const cssSelected = ( modType === ExperienceModificationType.CSS ) ? 'selected' : '';
		const jsSelected = ( modType === ExperienceModificationType.JS ) ? 'selected' : '';
		const textSelected = ( modType === ExperienceModificationType.TEXT  ) ? 'selected' : '';
		const markupSelected = ( modType === ExperienceModificationType.MARKUP ) ? 'selected' : '';
		const stylesSelected = ( modType === ExperienceModificationType.STYLES ) ? 'selected' : '';
		const addBeforeSelected = ( modType === ExperienceModificationType.ADD_BEFORE ) ? 'selected' : '';
		const addAfterSelected = ( modType === ExperienceModificationType.ADD_AFTER ) ? 'selected' : '';

		return (
			<div>
				{ !isLocked && 
					<div ref={ this.#controlsContainerRef } className='panel-cell experience-editor-controls'>
						<button
							className={ 'control-button ' }
							onClick={ () => this.#handleTogglePreview( !this.props.modificationsEnabled )  }>
								{ !this.props.modificationsEnabled && <VisibilityIcon size='22' color='#ffffff'/> }
								{ this.props.modificationsEnabled && <VisibilityOffIcon size='22' color='#ffffff'/> }
						</button>
						<button
							className={ 'control-button control-pad-left control-pad-right ' }
							onClick={ this.#handleToggleModificationsList.bind( this )}>
								<EditIcon size='26' color='#ffffff'/>
						</button>

						<button
							className={ `control-button control-pad-left ${ globalBtnsDisabled } ${ cssSelected }` }
							onClick={ () => this.#handleCreateModification( ExperienceModificationType.CSS ) }>
								<CssIcon size='26' color='#ffffff'/>
						</button>
						<button
							className={ `control-button control-pad-left control-pad-right ${ globalBtnsDisabled } ${ jsSelected }` }
							onClick={ () => this.#handleCreateModification( ExperienceModificationType.JS ) }>
								<JavaScriptIcon size='26' color='#ffffff'/>
						</button>

						<button
							className={ 'control-button control-pad-left ' + prevDisabled }
							onClick={ () => this.#handleElementNavigation( primaryElement.previousElementSibling )}>
								<ArrowLeftIcon size='26' color='#ffffff'/>
						</button>
						<button
							className={ 'control-button control-pad-left ' + nextDisabled }
							onClick={ () => this.#handleElementNavigation( primaryElement.nextElementSibling )}>
								<ArrowRightIcon size='26' color='#ffffff'/>
						</button>
						<button
							className={ 'control-button control-pad-left ' + upDisabled }
							onClick={ () => this.#handleElementNavigation( primaryElement.parentElement )}>
								<ArrowUpIcon size='26' color='#ffffff'/>
						</button>
						<button
							className={ 'control-button control-pad-left control-pad-right ' + downDisabled }
							onClick={ () => this.#handleElementNavigation( primaryElement.firstElementChild )}>
								<ArrowDownIcon size='26' color='#ffffff'/>
						</button>

						<button
							className= { `control-button control-pad-left ${ textDisabled } ${ textSelected }` }
							onClick={ () => this.#handleCreateModification( ExperienceModificationType.TEXT ) }>
								<NotesIcon size='26' color='#ffffff'/>
						</button>
						<button
							className={ `control-button control-pad-left ${ controlDisabled } ${ markupSelected }` }
							onClick={ () => this.#handleCreateModification( ExperienceModificationType.MARKUP ) }>
								<CodeIcon size='26' color='#ffffff'/>
						</button>
						<button
							className={ `control-button control-pad-left control-pad-right ${ controlDisabled } ${ stylesSelected }` }
							onClick={ () => this.#handleCreateModification( ExperienceModificationType.STYLES )}>
								<TextColorIcon size='24' color='#ffffff'/>
						</button>

						{/* <button
							className={ 'control-button control-pad-left ' + controlDisabled }
							onClick={ () => this.#handler( primaryElement.firstElementChild )}>
								<MoveIcon size='26' color='#ffffff'/>
						</button> */}
						<button
							className={ `control-button control-pad-left ${ addElementDisabled } ${ addBeforeSelected }` }
							onClick={ () => this.#handleCreateModification( ExperienceModificationType.ADD_BEFORE ) }>
								<InsertBeforeIcon size='22' color='#ffffff'/>
						</button>
						<button
							className={ `control-button control-pad-left ${ addElementDisabled } ${ addAfterSelected }` }
							onClick={ () => this.#handleCreateModification( ExperienceModificationType.ADD_AFTER )}>
								<InsertAfterIcon size='22' color='#ffffff'/>
						</button>
						<button
							className={ `control-button control-pad-left ${ controlDisabled }` }
							onClick={ () => this.#handleCreateModification( ExperienceModificationType.REMOVE ) }>
								<RemoveIcon size='26' color='#ffffff'/>
						</button>

						<div id='selected-element-path'>
							<div
								ref={ this.#pathInputRef }
								className='input'
								contentEditable={ true }
								suppressContentEditableWarning={ true }
								onBlur={ e => this.#handleElementPathInputChanged( e.target.textContent ) }>
									{ this.props.querySelector }
								</div>
							<button onClick={ this.#handleCopyElementPath.bind( this )}>
								<CopyIcon size={ 13 } color='#ffffff'/>
							</button>
						</div>
					</div>
				}
				{ isLocked &&
					<div ref={ this.controlsContainerRef } className='panel-cell experience-editor-controls'>
						<button
							className={ 'control-button ' }
							onClick={ () => this.#handleTogglePreview( !this.props.modificationsEnabled )  }>
								{ !this.props.modificationsEnabled && <VisibilityIcon size='22' color='#ffffff'/> }
								{ this.props.modificationsEnabled && <VisibilityOffIcon size='22' color='#ffffff'/> }
						</button>
						<button
							className={ 'control-button control-pad-left control-pad-right ' }
							onClick={ this.#handleToggleModificationsList.bind( this )}>
								<EditIcon size='26' color='#ffffff'/>
						</button>
					</div>
				}
			</div>
		);
	}

	//

	/**
	 * Handles the toggle to enable or disable previewing
	 */
	#handleTogglePreview() {
		this.props.togglePreviewHandler();
	}

	/**
	 * Handles the toggle to hide or show the modifications list
	 */
	#handleToggleModificationsList() {
		this.props.toggleModificationsListHandler();
	}

	/**
	 * Navigates the selected content to a specifc element
	 */
	#handleElementNavigation( element ) {
		this.props.elementNavigationHandler( element );
	}
	 
	/**
	 * Handles a click on one of the control buttons used to create a new modification
	 */
	#handleCreateModification( type ) {
		this.props.createModificationHandler( type );
	}

	/**
	 * Handles the click to copy the selected elements path
	 */
	#handleElementPathInputChanged( value ) {
		this.props.elementPathChangedHandler( value );
	}

	/**
	 * Handles the click to copy the selected elements path
	 */
	#handleCopyElementPath() {
		navigator.clipboard.writeText( this.#pathInputRef.current.textContent );
	}

	//

	/**
	 * @return the getBoundingClientRect of the components root element
	 */
	getBoundingClientRect() {
		const container = this.#controlsContainerRef?.current;
		return ( container != null ) ? container.getBoundingClientRect() : { y: 0 };
	}

}

//

export default ExperienceEditorControls;
