/*
 * (c) Verra Technology Corporation
 */

import Command from '../../commands/Command.mjs';
import UpSellComponent from '../components/UpSellComponent';
import PermissionsManager from '../managers/PermissionsManager';
import AdminStates from '../model/AdminStates';
import Permissions from '../model/Permissions';
import OpenModalCommand from './OpenModalCommand';
import SetStateCommand from './SetStateCommand';

/**
 * Initiates the view to create an Experience for an Optimization
 */
class CreateExperienceForOptimizationCommand extends Command {
	
	/**
	 * Handles execution of the command
	 */
	handleExecute() {
		const hasPermission = PermissionsManager.hasPermissionForMore( Permissions.EXPERIENCES_MAX );
		if( hasPermission.allowed ) {
			const setState = new SetStateCommand( AdminStates.ADMIN_EXPERIENCES_CREATE_FOR_OPTIMIZATION, null, false, false );
			setState.execute();
		} else {
			const msg = <p style={{ margin: 0 }}>You've reached your maximum number of allowed Experiences. Your account only allows { hasPermission.permitted }.</p>;
			const upSell = <UpSellComponent message={ msg } callout='experiences'/>;
			const openModal = new OpenModalCommand(  'Limit Reached', upSell, '600px', true );
			openModal.execute();
		}
	}
	
}

//

export default CreateExperienceForOptimizationCommand;
