/*
 * (c) Verra Technology Corporation
 */

import Command from '../../commands/Command.mjs';
import Alert from '../components/controls/Alert';
import SphereAdminSession from '../model/SphereAdminSession';
import ManageSubscriptionRequest from '../requests/account/ManageSubscriptionRequest';
import OpenModalCommand from './OpenModalCommand';

/**
 * Command used to initiate subscription management
 */
class ManageSubscriptionCommand extends Command {
	
	/**
	 * Handles execution of the command
	 */
	handleExecute() {
		SphereAdminSession.loading = true;
		const manage = new ManageSubscriptionRequest();
		manage.execute( this.#handleManageSubscriptionComplete.bind( this ));
	}

	/**
	 * Handles the completion of the ManageSubscriptionRequest
	 */
	#handleManageSubscriptionComplete( command ) {
		const redirect = command.getRedirect();
		if( redirect != null ) {
			window.location.href = redirect;
		} else {
			SphereAdminSession.loading = false;
			const alert = <Alert content='There was a problem managing your subscription. Reach out to support if the problem persists.' showCancelBtn={ false }/>;
			const openModal = new OpenModalCommand( 'Hmm, that\'s not right', alert, '500px', true );
			openModal.execute();
		}
	}
	
}

export default ManageSubscriptionCommand;
